import {mapActions, mapGetters} from "vuex";
import orderItem from '../../../../components/order-item/index.vue'
export default {
  name: "client-history",
  components:{
    orderItem
  },
  computed:{
    ...mapGetters({
      clientOrders:'profile/clientOrders'
    })
  },
  created() {
    this.fetchClientOrders(this.$route.params.id)
  },
  methods:{
    ...mapActions({
      fetchClientOrders:'profile/GET_CLIENT_ORDER'
    })
  }
}